<template>
  <b-card>

    <div>
      <div class="text-black text-2xl font-semibold">
        Tambah Data Event
      </div>
    </div>
    <div class="lg:p-4">
      <b-row class="mt-3">
        <b-col md="6">
          <b-form-group
            id="title_event"
            label-cols="4"
            label-cols-lg="3"
            label="Judul Event*"
            label-class="font-semibold text-7 text-[#333333]"
            label-for="title_event"
          >
            <b-form-input
              id="title_event"
              v-model="state.form.event_title"
              type="text"
              :maxlength="30"
              :minlength="3"
              placeholder="Buat judul event"
              :class="error.form.event_title?'border-primary': ''"
              required
            />
            <p
              v-if="error.form.event_title"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_title }}
            </p>
          </b-form-group>
          <b-form-group
            id="t"
            label-cols="4"
            label-cols-lg="3"
            label="Poster*"
            label-class="font-semibold text-7 text-black"
            label-for="poster"
            class="mt-[5rem]"
          >
            <b-input-group>
              <b-input-group-append
                class="d-flex align-items-center w-100"
                :class="error.form.poster?'border-primary': ''"
                style="
                    border-width: 1px;
                    border-radius: 5px;
                    border-color: #cfcfcf;
                    padding: 3px;
                  "
                @click="$refs.photoFile.click()"
              >
                <BButton
                  class="d-flex align-items-center ml-[4px] w-40 btn-upload"
                  variant="warning"
                  size="sm"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/document-upload.svg"
                    alt=""
                    width="15px"
                  >
                  <span
                    class="ml-[2px]"
                    style="font-size: 12px"
                  >
                    Pilih Foto</span>
                </BButton>
                <b-form-input
                  v-model="state.photoName"
                  class="text-file-foto"
                  style="border-left: none;
                      border-bottom-left-radius: 0%;
                      border-top-left-radius: 0%;
                      border: none;
                      background-color: white;"
                  :readonly="true"
                  :placeholder="state.photoName? state.photoName : 'upload Foto' "
                />
              </b-input-group-append>
              <input
                id="poster"
                ref="photoFile"
                class="form-control"
                type="file"
                style="display: none"
                @change="validateExt"
              >
            </b-input-group>
            <div
              :class="error.form.poster?'text-primary': ''"
              class="text-xs mt-[4px]"
            >
              *Upload foto dengan maksimal 1 Mb ukuran 300 x 300px
            </div>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Jumlah Peserta*"
            label-class="font-semibold text-7 text-black"
            label-for="max_participants"
            class="mt-[2rem]"
          >

            <b-form-input
              id="max_participants"
              v-model="state.form.max_attendance"
              type="number"
              placeholder="99"
              :class="error.form.max_attendance?'border-primary': ''"
              required
              @input="maxNumberLength($event, 5)"
              @keypress="isNumber($event)"
            />
            <p
              v-if="error.form.max_attendance"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.max_attendance }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Diselenggarakan*"
            label-cols="4"
            label-cols-lg="3"
            class="mt-[2rem]"
            label-class="font-semibold flex items-center text-7 text-black"
          >
            <b-form-radio-group
              v-model="state.form.event_status"
              :options="state.radio"
              style="accent-color: #F95031; margin-top: 0.6rem; margin-left: 0.6rem;"
              name="radio-inline"
              @change="statusEvent"
            />
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Lokasi*"
            label-cols="4"
            label-cols-lg="3"
            :class="error.form.event_location? 'mt-[3.8rem]' : ''"
            label-class="font-semibold text-7 text-black"
            label-for="location"
          >

            <b-form-input
              id="location"
              v-model="state.form.event_location"
              :value="state.form.event_status === 'online' ? 'Via Zoom Meeting' : state.form.location"
              type="text"
              :placeholder="state.form.event_status === 'offline'? 'Masukan lokasi event' : 'Masukan Link Webinar'"
              required
              :class="error.form.event_location?'border-primary': ''"
            />
            <p
              v-if="error.form.event_location"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_location }}
            </p>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Sub-Judul Event*"
            label-class="font-semibold text-7 text-black"
            label-for="sub_title_event"
          >

            <b-form-textarea
              id="sub_title_event"
              v-model="state.form.event_sub_title"
              rows="3"
              placeholder="Buat sub-judul event"
              :class="error.form.event_sub_title?'border-primary': ''"
            />
            <p
              v-if="error.form.event_sub_title"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_sub_title }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label-cols="4"
            label-cols-lg="3"
            label="Type Event*"
            label-class="font-semibold text-7 text-black"
            label-for="type_event"
          >
            <v-select
              id="type-event"
              v-model="state.form.event_type"
              :options="state.options"
              label="type_event"
              placeholder="Pilih type event"
              :class="error.form.event_type?'border-primary': ''"
            />
            <p
              v-if="error.form.event_type"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_type }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Harga*"
            label-cols="4"
            label-cols-lg="3"
            label-class="font-semibold text-7 text-black"
            label-for="price"
            :class="error.form.event_price?'mt-[0.6rem]': 'mt-[2.5rem]'"
          >

            <b-input-group>
              <template #prepend>
                <b-input-group-text style="background-color: #F4F4F4; color:#626262">
                  Rp
                </b-input-group-text>
              </template>
              <b-form-input
                id="price"
                v-model="state.form.event_price"
                type="text"
                placeholder="0"
                required
                :class="error.form.event_price?'border-primary': ''"
                @input="formatPrice"
                @keypress="handleInput($event)"
              />
            </b-input-group>
            <p
              v-if="error.form.event_price"
              class="text-xs mt-[4px] text-primary"
            >
              {{ error.form.event_price }}
            </p>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            label="Waktu*"
            label-cols="4"
            label-cols-lg="3"
            label-class="font-semibold text-7 text-black"
            label-for="date"
          >
            <date-range-picker
              ref="picker"
              v-model="state.dateRangeActivity"
              :locale-data="state.locale"
              :ranges="false"
              :show-ranges="false"
              class="w-[100%] mt-[1rem]"
              :class="error.form.start_date?'border-primary': ''"
            >
              <template
                v-slot:input="picker"
              >
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div
                    v-if="state.dateRangeActivity.startDate === null"
                  >
                    Pilih waktu event
                  </div>
                  <div v-else>
                    {{ DAY_MONTH_YEAR( picker.startDate ) }} - {{ DAY_MONTH_YEAR(picker.endDate ) }}
                  </div>
                  <div
                    class="ml-1"
                  >
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/calendar.svg"
                      alt="calendar"
                      srcset=""
                    >
                  </div>
                </div>

              </template>
            </date-range-picker>
            <p
              v-if="error.form.event_start_date"
              class="text-xs mt-[2px] text-primary"
            >
              {{ error.form.event_start_date }}
            </p>
          </b-form-group>
          <b-form-group
            id="link"
            label-cols="4"
            label-cols-lg="3"
            label="Link Landing Page"
            label-class="font-semibold text-7 text-black"
            label-for="link"
          >
            <b-form-input
              id="link"
              v-model="state.form.link_landingpage"
              type="url"
              placeholder="Masukkan Link Event"
              required
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="auto"
        >
          <div class="font-semibold text-7 text-black">
            Deskripsi*
          </div>

        </b-col>
        <b-col>
          <div class="pl-3">
            <b-form-textarea
              id="textarea-rows"
              v-model="state.form.description"
              placeholder="Masukan Deskripsi Event"
              rows="8"
              :maxlength="250"
              :class="error.form.description?'border-primary': ''"
            />
            <span class="text-xs flex justify-content-end"> {{ state.form.description.length }}/250</span>
            <p
              v-if="error.form.description"
              class="text-xs  text-primary"
            >
              {{ error.form.description }}
            </p>
          </div>

        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <div
          class="d-flex mt-4 md:mt-2 justify-end "
          style="gap: 1rem;"
        >
          <b-button
            variant="outline-primary"
            @click="handleBack"
          >
            Kembali
          </b-button>
          <b-button
            v-b-modal.modal-add-event
            variant="primary"
            :disabled="!state.form.event_type ||
              state.form.event_title.length >= 30 ||
              state.form.event_title.length <= 3 ||
              state.form.event_sub_title.length >=256||
              state.form.event_sub_title === ''||
              !state.photoName||
              state.form.max_attendance === '' ||
              state.form.max_attendance === '0' ||
              state.form.max_attendance.length >= 6 ||
              state.form.event_price === '' ||
              state.form.event_price.length >= 9 ||
              state.form.event_price.length <= 3 ||
              state.form.event_location.length >= 256 ||
              state.form.event_location === '' ||
              state.form.event_status === ''||
              state.dateRangeActivity.startDate === null ||
              state.dateRangeActivity.endDate === null ||
              state.form.event_sub_title === '' ||
              state.form.description === ''"
            @click="handleSubmit"
          >
            Simpan
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
      <modal-add-event
        @submit="handleConfirmSubmit"
      />
    </div>
  </b-card>

</template>

<script>
import { reactive, ref, watchEffect } from '@vue/composition-api'
import { newAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import router from '@/router'
import dateRangePicker from 'vue2-daterange-picker'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import { YEAR_MONTH_DAY, DAY_MONTH_YEAR } from '@/libs/filterDate'
import { isNumber } from '@/libs/helpers'
import { toastSuccess, toastError } from '../config'
import modalAddEvent from './modalAddEvent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: {
    vSelect,
    dateRangePicker,
    modalAddEvent,
  },

  setup() {
    const picker = ref({
      startDate: firstDateOfMonth,
      endDate: lastDateOfMonth,
    })
    const error = reactive({
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
    })
    const state = reactive({
      photoName: '',
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      dateRangeUpdate: {
        startDate: today,
        endDate: today,
      },
      dateRangeActivity: {
        startDate: null,
        endDate: null,
      },
      data: {},
      form: {
        event_title: '',
        event_sub_title: '',
        event_type: '',
        event_status: '',
        event_price: '',
        event_location: '',
        max_attendance: '',
        event_start_date: '',
        event_end_date: '',
        poster: '',
        description: '',
        link_landingpage: '',
      },
      radio: [
        { text: 'Online', value: 'online' },
        { text: 'Ofline', value: 'offline' },
      ],
      options:
      ['Bootcamp', 'Gathering', 'Kopdar Komunitas', 'Webinar'],
    })

    const photoUpload = ref({})

    function statusEvent() {
      if (state.form.status_event === 'online') {
        state.form.location = 'Via Zoom Meeting'
      } else {
        state.form.location = ''
      }
    }
    function handleSubmit() {
      const price = state.form.event_price.split('.').join('')
      const formData = new FormData()
      formData.append('event_type', state.form.event_type)
      formData.append('event_title', state.form.event_title)
      formData.append('event_sub_title', state.form.event_sub_title)
      formData.append('event_status', state.form.event_status)
      formData.append('event_price', parseInt(price, 10))
      formData.append('event_location', state.form.event_location)
      formData.append('max_attendance', parseInt(state.form.max_attendance, 10))
      formData.append('event_start_date', YEAR_MONTH_DAY(state.dateRangeActivity.startDate))
      formData.append('event_end_date', YEAR_MONTH_DAY(state.dateRangeActivity.endDate))
      formData.append('poster', photoUpload.value)
      formData.append('description', state.form.description)
      formData.append('link_landingpage', state.form.link_landingpage)

      // Assign 'formData' to 'data.value'
      state.data = formData
    }

    function handleConfirmSubmit() {
      const url = '/komclass/api/v2/event'
      newAxiosIns.post(url, state.data)
        .then(res => {
          const { data } = res.data
          router.push({
            name: 'data-event',
          })
          toastSuccess('Berhasil menambahkan event')
        })
        .catch(err => {
          router.push({
            name: 'data-event',
          })
          toastError('Ada kesalahan saat menambah event')
        })
    }

    function handleBack() {
      router.back()
    }

    const validateSize = evChange => {
      const fileUpload = evChange.target
      const file = fileUpload.files[0]

      if (file) {
        const maxSize = 1024 * 1024
        if (file.size > maxSize) {
          toastError('Ukuran file terlalu besar. Maksimal 1 MB.')
          photoUpload.value = ''
          state.photoName = ''
        } else {
          photoUpload.value = file
        }
      }
    }
    const validateExt = evChange => {
      const file = evChange.target.value
      const fileExtension = file.substr(file.lastIndexOf('.') + 1)
      if (
        fileExtension !== 'jpg'
        && fileExtension !== 'jpeg'
        && fileExtension !== 'png'
        && fileExtension !== 'gif'
      ) {
        toastError('Hanya dapat upload file dengan ekstensi JPG dan PNG')
        state.photoName = ''
      } else {
        state.photoName = evChange.target.files[0].name
        validateSize(evChange)
      }
    }

    const maxNumberLength = (event, maxLength) => {
      const modifiedEvent = { ...event }
      if (modifiedEvent.value.length > maxLength) {
        modifiedEvent.value = modifiedEvent.value.slice(0, maxLength)
      }
    }

    const handleInput = event => {
      const allowedChars = /[0-9\b]/

      if (allowedChars.test(event.key)) {
        const inputValue = event.target.value.replace(/[^\d]/g, '') // Hapus karakter selain digit
        state.form.event_price = inputValue
      } else {
        event.preventDefault()
      }
    }

    const isEmpty = () => {
      if (!state.form.event_type) {
        error.form.event_type = 'Masukkan Type Event'
      } else {
        error.form.event_type = ''
      }

      if (!state.form.event_title.length) {
        error.form.event_title = 'Masukkan Judul Event'
      } else {
        error.form.event_title = ''
      }

      if (!state.form.event_sub_title.length) {
        error.form.event_sub_title = 'Masukkan Sub-Judul Event'
      } else {
        error.form.event_sub_title = ''
      }

      if (!state.form.event_price.length) {
        error.form.event_price = 'Masukkan Harga Event'
      } else {
        error.form.event_price = ''
      }

      if (!state.form.event_location.length) {
        error.form.event_location = 'Masukkan Lokasi Event'
      } else {
        error.form.event_location = ''
      }

      if (!state.form.max_attendance.length) {
        error.form.max_attendance = 'Masukkan Jumlah Peserta'
      } else {
        error.form.max_attendance = ''
      }

      if (!state.form.description.length) {
        error.form.description = 'Masukkan Deskripsi Event'
      } else {
        error.form.description = ''
      }

      if (state.dateRangeActivity.startDate === null) {
        error.form.event_start_date = 'Masukkan Waktu Event'
      } else {
        error.form.event_start_date = ''
      }

      if (!state.photoName) {
        error.form.poster = 'Masukkan Waktu Event'
      } else {
        error.form.poster = ''
      }
    }

    const formatPrice = () => {
      const inputValue = state.form.event_price
      const regex = /\B(?=(\d{3})+(?!\d))/g
      const formattedValue = inputValue.replace(regex, '.')
      state.form.event_price = formattedValue
    }

    watchEffect(() => {
      isEmpty()
    })

    return {
      picker,
      router,
      state,
      error,
      isNumber,
      handleSubmit,
      statusEvent,
      handleConfirmSubmit,
      handleBack,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      validateExt,
      validateSize,
      photoUpload,
      maxNumberLength,
      formatPrice,
      handleInput,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
